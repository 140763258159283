import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { ModalElem } from '../../common/modal';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import filterIcon from '../../asset/svg/common/filter.svg';
import filterIconModal from '../../asset/svg/common/filter-active.svg';

import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { GridElem } from '../../common/grid';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import {
  ANIMAL_TYPE_OPTION_LIST,
  COMPATIBILITY_ANIMAL_OPTION_LIST,
  PLACEMENT_OPTION_LIST,
  TRANSPORTATION_OPTION_LIST,
} from '../profile-psycho-update-specialization/constant';
import {
  SPECIALIST_OPTION_LIST,
  YES_NO_OPTION_LIST,
} from '../profile-user-update-data/constant';
import {
  AGE_OPTION_LIST,
  CITY_OPTION_LIST,
  FORM_VALUE_ENUM,
  GENDER_OPTION_LIST,
  POPULARITY_ORDER_OPTION_LIST,
  PRICE_ORDER_OPTION_LIST,
} from './constant';
import { FormikValues } from 'formik';
import { TabThirdElem } from '../../common/tab-third';
import { FieldTextElem } from '../../common/field-text';
import { DatePickerElem } from '../../common/date-picker';
import { timeOption } from '../../data/time/constant';
import { convertDate } from './convert';
import { CATEGORY_OPTION_LIST } from '../profile-psycho-create-category/constant';
import { HeaderContainer } from '../header';
import { FlexElem } from '../../common/flex';
import { DateField } from '../../common/field-date';
import { ModalEvent } from '../../common/modal-event';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
  // active: number;
  // setActive: Function;
  onResetForm: Function;
  setInitialValueForm: Function;
  getFormikValuesFromLocalStorage: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  setValue,
  // active,
  // setActive,
  onResetForm,
  setInitialValueForm,
  getFormikValuesFromLocalStorage,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };
  const [modalVisible, setModalVisible] = useState(false);

  const modalClose = (e: any) => {
    const localStorageData = getFormikValuesFromLocalStorage();
    setInitialValueForm({ ...localStorageData });
    formik.resetForm();
    setModalVisible(false);
  };
  const modalOpen = () => {
    setModalVisible(true);
  };

  const tabList = [
    { tid: 'Всі', value: 0 },
    { tid: 'USER.PSYCHOLOGIST.SELECT.CATEGORY.WALK', value: 1 },
    { tid: 'USER.PSYCHOLOGIST.SELECT.CATEGORY.FEED', value: 2 },
    { tid: 'USER.PSYCHOLOGIST.SELECT.CATEGORY.HOLD', value: 3 },
  ];

  return (
    <Container>
      {/* <TabThirdElem tabList={tabList} active={active} setActive={setActive} /> */}
      {/* {ReactDOM.createPortal(
        document.getElementById('layoutApp') || document.body,
      )} */}
      <HeadContaier>
        <TextElem
          tid="CARETAKER_LIST.HEADER"
          type="bold"
          size="title"
          color="textPlaceholder"
        />
        <FilterButton
          type="button"
          onClick={() => modalOpen()}
          tid="CARETAKER_LIST.FILTER.BUTTON"
          iconRight={filterIcon}
          color={COLOR_ENUM.ICON_THIRD}
        />
      </HeadContaier>
      ,
      <ModalElem
        titleTid="CARETAKER_LIST.MODAL.HEADER"
        open={modalVisible}
        onClose={modalClose}
        icon={filterIconModal}
      >
        {/* <StyledGrid>
          <FilterIconStyled />
          <TextElem
            tid="CARETAKER_LIST.MODAL.HEADER"
            color="modalHeaderDefault"
            size="label"
            type="semi-bold"
          />
          <CloseIconStyled onClick={modalClose} />
        </StyledGrid> */}
        <SelectContainerDesktop>
          <SelectElem
            isClearable
            placeholder="CARETAKER_LIST.MODAL.CATEGORY"
            name={FORM_VALUE_ENUM.CARETAKER_CATEGORY}
            onChange={setFieldValue}
            options={CATEGORY_OPTION_LIST}
            errorMessage={getFieldError(FORM_VALUE_ENUM.CARETAKER_CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.CARETAKER_CATEGORY)}
            value={setValue(
              CATEGORY_OPTION_LIST,
              FORM_VALUE_ENUM.CARETAKER_CATEGORY,
            )}
          />
          <DoubleContainerElem>
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.MODAL.ANIMAL_TYPE"
              isMulti
              name={FORM_VALUE_ENUM.ANIMAL_TYPE_LIST}
              options={ANIMAL_TYPE_OPTION_LIST}
              errorMessage={getFieldError(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.ANIMAL_TYPE_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{
                MultiValueContainer: multiValueContainer,
              }}
            />
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.MODAL.SPECIALIST_WITH"
              isMulti
              name={FORM_VALUE_ENUM.SPECIALIST_LIST}
              options={SPECIALIST_OPTION_LIST}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.SPECIALIST_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{
                MultiValueContainer: multiValueContainer,
              }}
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.MODAL.PLACEMENT_LIST"
              name={FORM_VALUE_ENUM.PLACEMENT_LIST}
              options={PLACEMENT_OPTION_LIST}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PLACEMENT_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.PLACEMENT_LIST)}
              value={setValue(
                PLACEMENT_OPTION_LIST,
                FORM_VALUE_ENUM.PLACEMENT_LIST,
              )}
              onChange={setFieldValue}
            />
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.MODAL.GENDER"
              name={FORM_VALUE_ENUM.GENDER}
              onChange={setFieldValue}
              options={GENDER_OPTION_LIST}
              errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
              error={isFieldError(FORM_VALUE_ENUM.GENDER)}
              value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              isClearable
              name={FORM_VALUE_ENUM.CITY}
              onChange={setFieldValue}
              placeholder="CARETAKER_LIST.MODAL.CITY"
              value={setValue(CITY_OPTION_LIST, FORM_VALUE_ENUM.CITY)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
              error={isFieldError(FORM_VALUE_ENUM.CITY)}
              options={CITY_OPTION_LIST}
            />
            <SelectElem
              isClearable
              name={FORM_VALUE_ENUM.AGE}
              onChange={setFieldValue}
              placeholder="CARETAKER_LIST.MODAL.AGE.PLACEHOLDER"
              value={setValue(AGE_OPTION_LIST, FORM_VALUE_ENUM.AGE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.AGE)}
              error={isFieldError(FORM_VALUE_ENUM.AGE)}
              options={AGE_OPTION_LIST}
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              isClearable
              name={FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL}
              onChange={setFieldValue}
              options={COMPATIBILITY_ANIMAL_OPTION_LIST}
              placeholder="CARETAKER_LIST.MODAL.COMPATIBILITY_ANIMAL"
              errorMessage={getFieldError(FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL)}
              error={isFieldError(FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL)}
              value={setValue(
                COMPATIBILITY_ANIMAL_OPTION_LIST,
                FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL,
              )}
            />
            <SelectElem
              isClearable
              name={FORM_VALUE_ENUM.MEDICATION_EXPERIENCE}
              onChange={setFieldValue}
              options={YES_NO_OPTION_LIST}
              placeholder="CARETAKER_LIST.MODAL.MEDICAL_EXPERIENCE"
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.MEDICATION_EXPERIENCE,
              )}
              error={isFieldError(FORM_VALUE_ENUM.MEDICATION_EXPERIENCE)}
              value={setValue(
                YES_NO_OPTION_LIST,
                FORM_VALUE_ENUM.MEDICATION_EXPERIENCE,
              )}
            />
          </DoubleContainerElem>

          <FieldTextElem
            name={FORM_VALUE_ENUM.SEARCH}
            onChange={(event: any) => {
              formik.handleChange(event);
            }}
            onBlur={formik.handleBlur}
            placeholder="CARETAKER_LIST.FILTER.SEARCH"
            value={formik.values[FORM_VALUE_ENUM.SEARCH]}
          />

          <DoubleContainerElem>
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.FILTER.POPULARITY_ORDER"
              name={FORM_VALUE_ENUM.POPULARITY_ORDER}
              options={POPULARITY_ORDER_OPTION_LIST}
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.POPULARITY_ORDER,
                formik,
              )}
              error={isFieldError(FORM_VALUE_ENUM.POPULARITY_ORDER, formik)}
              value={formik.values[FORM_VALUE_ENUM.POPULARITY_ORDER]}
              onChange={(...e: any) => {
                formik.setFieldValue(...e);
              }}
              isDisabled={isLoading}
            />
            <SelectElem
              isClearable
              placeholder="CARETAKER_LIST.FILTER.PRICE_ORDER"
              name={FORM_VALUE_ENUM.PRICE_ORDER}
              options={PRICE_ORDER_OPTION_LIST}
              error={isFieldError(FORM_VALUE_ENUM.PRICE_ORDER, formik)}
              value={formik.values[FORM_VALUE_ENUM.PRICE_ORDER]}
              onChange={(...e: any) => {
                formik.setFieldValue(...e);
              }}
              isDisabled={isLoading}
            />
          </DoubleContainerElem>

          {getFieldValue(FORM_VALUE_ENUM.CARETAKER_CATEGORY) && (
            <>
              <DoubleContainerElem>
                {/* <StyledDatePicker
                    name={FORM_VALUE_ENUM.DATE}
                    placeholder="CARETAKER_LIST.MODAL.DATE"
                    handleChange={(e: any) => {
                      console.log(e);
                      // formik.setFieldValue(FORM_VALUE_ENUM.DATE, {
                      //   value: e,
                      // });
                      formik.setFieldValue(FORM_VALUE_ENUM.DATE, e);
                    }}
                    initialValue={convertDate(
                      getFieldValue(FORM_VALUE_ENUM.DATE) || null,
                    )}
                  /> */}
                <DateField
                  selectedDate={getFieldValue(FORM_VALUE_ENUM.DATE)}
                  setSelectedDate={(e: string) =>
                    setFieldValue(FORM_VALUE_ENUM.DATE, { value: e })
                  }
                  placeholder="CARETAKER_LIST.MODAL.DATE"
                />
                <SelectElem
                  isMulti
                  isClearable
                  dynamic
                  name={FORM_VALUE_ENUM.TIME}
                  onChange={onChangeSelect}
                  onBlur={formik.handleBlur}
                  placeholder="CARETAKER_LIST.MODAL.TIME"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
                  error={isFieldError(FORM_VALUE_ENUM.TIME)}
                  options={timeOption}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={getFieldValue(FORM_VALUE_ENUM.TIME)}
                  customComponents={{
                    MultiValueContainer: multiValueContainer,
                  }}
                />
              </DoubleContainerElem>
              <FieldTextElem
                type="number"
                name={FORM_VALUE_ENUM.PRICE}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="CARETAKER_LIST.MODAL.PRICE"
                value={getFieldValue(FORM_VALUE_ENUM.PRICE)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PRICE)}
                error={isFieldError(FORM_VALUE_ENUM.PRICE)}
              />
            </>
          )}
          <ButtonContainer>
            <StyledButton
              type="submit"
              tid="CARETAKER_LIST.MODAL.BUTTON.SUBMIT"
              onClick={() => setModalVisible(false)}
            />
            <StyledButton
              type="save"
              tid="CARETAKER_LIST.MODAL.BUTTON.RESET"
              onClick={() => onResetForm()}
            />
          </ButtonContainer>
        </SelectContainerDesktop>
      </ModalElem>
    </Container>
  );
};

const SelectContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  width: 100%;
  justify-content: end;
  z-index: 600;
  width: 100%;
  max-width: 600px;
`;

const HeadContaier = styled(FlexElem)`
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledGrid = styled(GridElem)`
  grid-template-columns: ${Spacing(5)} auto 18px;
`;

// const FilterIconStyled = styled(FilterIcon)`
//   height: ${Spacing(5)};
//   width: ${Spacing(5)};
//   path {
//     fill: ${COLOR_DATA[COLOR_ENUM.DEFAULT]};
//   }
// `;

const StyledDatePicker = styled(DatePickerElem)`
  height: 54px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  .ant-picker-input > input {
    max-width: none;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  }
  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
  }
  :hover > .ant-picker-outline {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
  }
`;

const StyledModal = styled(ModalElem)`
  ::part(content) {
    box-sizing: border-box;
    max-width: 700px;
    width: 100%;
    overflow: visible;
  }
  & > div {
    display: grid;
    max-width: 700px;
    gap: ${Spacing(5)};
  }
`;

const ButtonContainer = styled(DoubleContainerElem)`
  gap: ${Spacing(2)};
`;

const StyledButton = styled(ButtonElem)`
  border-radius: 9px;
  padding: ${Spacing(4)} ${Spacing(5)};

  ${({ type }) => {
    if (type != 'submit') {
      return css`
        background: ${COLOR_DATA[COLOR_ENUM.BUTTON_SETTING_LOG_OUT]};
        span {
          color: ${COLOR_DATA[COLOR_ENUM.BUTTON_SETTING_LOG_OUT_TEXT]};
          font-weight: ${SIZE_FONT_WEIGHT_DATA[
            SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD
          ]};
        }
      `;
    }
    return css`
      span {
        font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]} !important;
      }
    `;
  }}
`;

const FilterButton = styled(ButtonElem)`
  background: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: 9px;
  padding: ${Spacing(4)} ${Spacing(5)};
  width: 181px;
  div {
    gap: ${Spacing(2)};
  }
  span {
    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.SEMI_BOLD
    ]} !important;
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_BUTTON_CLICK]} !important;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    div {
      justify-content: space-between;
    }
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  align-items: center;
  // width: 100%;
  // display: flex;
  // justify-content: space-between;
  // margin: 0 auto;
  // margin-top: ${Spacing(10)};
  // align-self: end;
  // align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );

  // @media screen and (max-width: 900px) {
  //   // position: static;
  //   flex-direction: column;
  //   gap: ${Spacing(6)};
  //   padding: ${Spacing(4)};
  // }
`;
